import Button from "./form/Button";
import Image from "next/image";
import Poster from "./Poster";
import Link from "next/link";
import {routes} from "@/helper/routes";

import type {RecommendedListItemType, RecommendedListType} from "@/types/listType";

interface ListOfTheWeekProps {
  listOfWeek: RecommendedListType;
}

// const exampleCover = "https://firebasestorage.googleapis.com/v0/b/nerede-izlenir-4c60d.appspot.com/o/list-images%2FSu%CC%88priz%20sonlu%20filmler-cover%201%20(2).png?alt=media&token=baf4a21b-ac19-4130-bee8-1582e8b3e7bf";
function ListOfTheWeek({listOfWeek}: ListOfTheWeekProps) {
  if (!listOfWeek) return null;
  return (
    <Link href={`${routes.recommendedList.index}/${listOfWeek.url}`}>
      {renderMobile()}
      {renderWeb()}
    </Link>
  );

  function renderMobile() {
    return (
      <section className="list-week-mobile-body d-lg-none">
        <p className="home-section-label text-center" style={{fontSize: 16}}>
          Haftanın Listesi
        </p>
        <div className="media-picture-wrapper aspect-ratio-16_9 my-2">
          <Image
            className="media-picture"
            src={listOfWeek.cover}
            alt={listOfWeek.metaTitle}
            title={listOfWeek.metaTitle}
            fill
          />
        </div>
        <p className="list-week-content-title">
          "{listOfWeek.title}" ni hemen inceleyebilirsin!
        </p>
      </section>
    );
  }

  function renderWeb() {
    return (
      <section className="mt-5 d-none d-lg-block">
        <p className="home-section-label mb-3">Haftanın Listesi</p>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="media-picture-wrapper aspect-ratio-16_9">
              <Image
                className="media-picture"
                src={listOfWeek.cover}
                alt={listOfWeek.metaTitle}
                title={listOfWeek.metaTitle}
                fill
              />
            </div>
            <div className="mt-3">
                <p className="home-section-label mb-1">{listOfWeek.title}</p>
                <p className="home-section-description">{listOfWeek.description}</p>
            </div>
          </div>
          <div className='col-6'>
            <div className="list-week-item-grid">
              {listOfWeek.items
                ?.slice(0, 4)
                ?.map((i: RecommendedListItemType | any) => (
                  <div key={i.id} className="row">
                    <div className="col-5">
                      <div className="media-picture-wrapper aspect-ratio-10_15">
                        <Poster
                          content={{
                            ...i,
                            imageUrl: i.poster,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-7">
                      <p className="list-week-label mb-1">{i.title}</p>
                      <p className="home-section-description">{i.description}</p>
                    </div>
                  </div>
                ))}
            </div>
            <Button
              className="mt-4"
              size="md"
              buttonType="primary-outline"
              block
            >
              Listeyi detaylı incele
            </Button>
          </div>
        </div>
      </section>
    );
  }
}

export default ListOfTheWeek;
