import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Head from "./Head";
import GoogleAd from "../components/GoogleAd";
import {useRouter} from "next/router";
import type {ReactNode} from 'react';
import type {MetaType} from "@/types/dataType";
import type {AuthorizationType} from "@/helper/constants";
import useBreakpoint from "@/hooks/useBreakpoint";

export interface MainProps {
    children: ReactNode,
    meta?: MetaType | any,
    showAds?: boolean;
    authorization?: AuthorizationType
}

export default function Main({children, meta, showAds = false, authorization = "default"}: MainProps) {
    const router = useRouter();
    const [showPage, setShowPage] = useState(authorization === "default");
    const isLoggedIn = useSelector((state: any) => state.authReducer.isLoggedIn);
    // 2050 den kucukse true olur
    const breakpoint = useBreakpoint(2050);

    const checkAuth = () => {
        if (authorization === "default") return true;
        if (authorization === "guest" && isLoggedIn) return false;
        if (authorization === "user" && !isLoggedIn) return false;
        return true;
    };

    useEffect(() => {
        const auth = checkAuth();
        if (!auth) {
            setShowPage(false);
            router.push("/");
        } else {
            setShowPage(true);
        }
    }, [isLoggedIn]);

    return showPage ? (
        <>
            <Head meta={meta}/>
            <main id="main">
                {showAds && breakpoint === false && (
                    <div className="googlead-side" style={{left: 0}}>
                        <GoogleAd slot={"sideLeft"}/>
                    </div>
                )}
                {children}
                {showAds && breakpoint === false && (
                    <div className="googlead-side" style={{right: 0}}>
                        <GoogleAd slot={"sideRight"}/>
                    </div>
                )}
            </main>
        </>
    ) : null;
}

