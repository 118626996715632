import React, {memo} from "react";
import NextHead from "next/head";
import {getHomePageMeta} from "@/helper/meta";
import type {MetaType} from "@/types/dataType";


/**
 * This is Dynamic head for static head check _document.js
 */
const Head = memo(({meta = {} as MetaType}: { meta?: MetaType }) => {
  const poster = meta.poster || 'https://neredeizlenir.com/images/twitter_card.png';
  return (
    <NextHead>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description}/>
      <link rel="canonical" href={meta.canonical}/>
      <meta name="keywords" content={meta.keywords}/>

      {/*<meta name="viewport" content="user-scalable=no, width=1200, initial-scale=0"/>*/}
      {/*<meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no"/>*/}
      {/*<meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0"/>*/}
      <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover maximum-scale=1"/>
      <meta name="theme-color" content="#141414"/>

      <meta name="country" content="Turkey"/>
      <meta name="content-language" content="tr"/>
      <meta name="msapplication-TileColor" content="#1d3557"/>
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
      <meta name="theme-color" content="#1d3557"/>
      <meta name="application-name" content="Neredeizlenir"/>
      <meta name="apple-mobile-web-app-title" content="Neredeizlenir"/>
      <meta name="apple-mobile-web-app-capable" content="yes"/>
      <meta name="mobile-web-app-capable" content="yes"/>
      <meta name="apple-touch-fullscreen" content="yes"/>
      <meta name="robots" content="index, follow"/>

      {/*OPEN GRAPH*/}
      <meta property="og:title" content={meta.title}/>
      <meta property="og:description" content={meta.description}/>
      <meta property="og:url" content={meta.canonical}/>
      <meta property="og:image" content={poster}/>
      <meta property="og:image:width" content={meta.metaImageWidth || "1481.33"}/>
      <meta property="og:image:height" content={meta.metaImageHeight || "1000"}/>
      <meta property="og:type" content="website"/>
      <meta property="og:locale" content="tr"/>
      <meta property="og:site_name" content="Neredeizlenir"/>

      {/*TWITTER*/}
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:description" content={meta.description}/>
      <meta name="twitter:title" content={meta.title}/>
      <meta name="twitter:image" content={poster}/>
      <meta name="twitter:site" content="@neredeizlenir"/>
      <meta property="twitter:image:alt" content={meta.description}/>

      <meta property="article:publisher" content="https://www.twitter.com/neredeizlenir"/>
    </NextHead>
  );
});
export default Head;
