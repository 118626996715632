import {returnValidPoster} from "@/components/Poster";
import {routes} from "./routes";
import type {MetaType} from "@/types/dataType";
import type {ContentType} from "@/types/contentType";
import type {RecommendedListType} from "@/types/listType";

const platforms = "Netflix, Disney +, BluTv, Exxen, Gain, PuhuTv, Amazon Prime, Tod, GooglePlay, AppleTv"
export const metaDefault = {
  description: `${platforms} gibi platformlarda filmlerin dizilerin yayında olup olmadığını kontrol edebileceğiniz uygulama`,
  keywords: platforms + ', Film, Dizi, izle',
  description2: "Nerede izlenir, popüler trendleri, günlük film ve haftalık dizi önerileri sunan bir platformdur. Seçtiğimiz en iyi içerikleri keşfedin! Netflix, Disney ve diğer tüm platformlarda keyifle izleyin!",
}

export function getHomePageMeta(): MetaType {
  return {
    title: `Film ve Dizi Dünyasına Hoşgeldin - Nerede izlenir`,
    description: metaDefault.description2,
    canonical: `https://neredeizlenir.com/`,
    keywords: metaDefault.keywords,
  }
}

export function getContentsPageMeta({asPath = '', query = {}}: { asPath?: string, query: any }): MetaType {
  const streamNames = {
    '8': 'Netflix',
    '337': 'Disney Plus',
    '119': 'Amazon Prime',
    '341': 'Blu Tv',
    '11': 'Mubi',
    '1750': 'Tod',
    '342': 'Puhu Tv',
    '2': 'Apple Tv',
    '3': 'Google Play'
  };
  const genres = query.genres?.split(',')?.[0];
  const stream: keyof typeof streamNames = query.streams?.split(',')?.[0];

  let description = 'En İyi Film ve Dizileri hangi platformlarda izleyebileceginizi keşfedebilir, arama yapabilir ve dilediğiniz gibi filtreleyebilirsiniz.'

  let mediaText = 'Film ve Diziler';
  if (query.mediaType === 'film') mediaText = 'Filmler';
  if (query.mediaType === 'dizi') mediaText = 'Diziler';
  if (stream || genres) mediaText = `${mediaText}i`;

  let queryText = `Popüler ${streamNames[stream] ? `${streamNames[stream]} ` : ''}${genres ? `${genres} ` : ''}${mediaText}`
  return {
    title: `${queryText} Nereden İzlenir? - Nerede izlenir`,
    description,
    canonical: `https://neredeizlenir.com${asPath}`,
    keywords: metaDefault.keywords,
  }
}

export function getContentPageMeta({content, mediaType}: { content?: ContentType, mediaType: string }): MetaType {
  // @ts-ignore
  if (!content) return {};
  const detailGenres = content?.genres || [];

  let yearText = content.year || content.yearNumber || '';
  if (yearText) yearText = `(${yearText})`;
  // let secondTitle = returnContentInfoWithLanguage(content.title, 'en') || returnContentInfoWithLanguage(content.title, 'original')
  let typeText = '';
  if (mediaType === 'film') typeText = 'filmi';
  else if (mediaType === 'dizi') typeText = 'dizisi';
  return {
    // max 60
    title: `${content.title} ${yearText} - İnternette nereden izlenir?`,
    // max 160
    description: `${content.description.slice(0, 125)} - Hangi platformda yayında?`,
    canonical: `https://neredeizlenir.com/${content.slugUrl}`,
    poster: returnValidPoster(content.imageUrl, 'w342'),
    keywords: detailGenres.join(', ')
  }
}

export function getRoulettePageMeta(): MetaType {
  return {
    title: `Rulet, Bugün ne izlesem? - Nerede izlenir`,
    description: "Ne izleyeceğini konusunda kararsız mısın? Filtrele, ruleti çevir, film ve dizi kataloğundan rastgele bir içerik keşfet, anında online izle.",
    canonical: `https://neredeizlenir.com${routes.roulette}`,
    keywords: metaDefault.keywords,
  }
}

export function getListPageMeta(): MetaType {
  return {
    title: `Film ve Dizi Tavsiye Listeleri - Nerede izlenir`,
    description: `Senin için özenle hazırladığımız en popüler film ve dizi listelerini hangi plotformlarda izlenineceğini keşfet.`,
    canonical: 'https://neredeizlenir.com/tavsiye-listesi',
    keywords: metaDefault.keywords,
  }
}

export function getListDetailPageMeta({data}: { data?: RecommendedListType }): MetaType {
  // @ts-ignore
  if (!data) return {};
  return {
    title: `${data.metaTitle} - Nerede izlenir`,
    description: `${data.metaDescription}`,
    poster: data.cover,
    canonical: `https://neredeizlenir.com/tavsiye-listesi/${data.url}`,
    keywords: metaDefault.keywords,
  }
}

export function getProfilePageMeta({userName}: { userName: string }): MetaType {
  return {
    title: `${userName} Profil  - Nerede izlenir`,
    description: `Nerede izlenir, ${userName} profil sayfası`,
    canonical: `https://neredeizlenir.com/profile`,
    keywords: metaDefault.keywords,
  }
}

type UserListMetaProps = {
  userName: string;
  title: string;
  description: string;
  asPath: string;
  imageUrl?: string;
}

export function getUserListMeta({userName, title, description, asPath, imageUrl}: UserListMetaProps): MetaType {
  return {
    title: `${title} - Nerede izlenir`,
    description: `${description} - Nerede izlenir ${userName} listesi`,
    canonical: `https://neredeizlenir.com${asPath}`,
    poster: returnValidPoster(imageUrl, 'w342'),
    keywords: metaDefault.keywords,
  }
}

export function getSearchPageMeta(q: string, asPath: string): MetaType {
  return {
    title: `Arama, ${q} film ve dizileri? - Nerede izlenir`,
    description: metaDefault.description2,
    canonical: `https://neredeizlenir.com${asPath}`,
    keywords: metaDefault.keywords,
  };
}

export function getProductionMeta(name: string, asPath: string): MetaType {
  return {
    title: `${name} film ve dizileri - Nerede izlenir`,
    description: `En iyi ${name} filmlerini keşfedin! 'Nerede İzlenir' ile tüm ${name} yapımlarını arayın, inceleyin ve nereden izleyebileceğinizi öğrenin.`,
    canonical: `https://neredeizlenir.com${asPath}`,
    keywords: metaDefault.keywords,
  };
}

export function getPeopleMeta(name: string, asPath: string): MetaType {
  return {
    title: `${name} film ve dizileri - Nerede izlenir`,
    description: `Tüm ${name} filmlerini keşfedin ve nereden izleyeceğinizi öğrenin! 'Nerede İzlenir' ile ${name} başrolde olduğu hit filmleri bulun, detayları inceleyin ve izleme seçeneklerini keşfedin.`,
    canonical: `https://neredeizlenir.com${asPath}`,
    keywords: metaDefault.keywords,
  };
}

export function getLiveStreamHomeMeta(asPath: string, genre: string): MetaType {
  if (genre) {
    return {
      title: `${genre} Canlı TV Yayınları - ${genre} Kategorisindeki En Popüler Kanallar`,
      description: `${genre} kategorisindeki en popüler ve güncel canlı TV yayınlarını keşfedin. ${genre} ile ilgili tüm kanallar ve günlük yayın akışları burada.`,
      canonical: `https://neredeizlenir.com${asPath}`,
      keywords: 'canlı tv, tv yayınları, canlı tayın, tv rehberi, canlı tv izle, tv izle, canlıtv, live tv, seyret',
    }
  }
  return {
    title: "Canlı TV izle - Türkiye'nin En Güncel ve Kapsamlı TV Rehberi",
    description: "Türkiye'nin dört bir yanından canlı TV yayınlarını kesintisiz izleyin. En popüler kanalları bir arada bulabileceğiniz, günlük yayın akışları ile güncel kalabileceğiniz en kapsamlı TV rehberi.",
    canonical: `https://neredeizlenir.com${asPath}`,
    keywords: 'canlı tv, tv yayınları, canlı tayın, tv rehberi, canlı tv izle, tv izle, canlıtv, live tv, seyret',
  }
}

export function getLiveStreamChannelPageMeta(channelName: string, channelSlugUrl: string): MetaType {
  return {
    title: `${channelName} Canlı Yayın izle - ${channelName} Günlük Yayın Akışı ve Detaylar`,
    description: `${channelName}’nin canlı yayınını buradan izleyin. ${channelName} ile ilgili tüm detaylar, günlük yayın akışı ve daha fazlası için tıklayın.`,
    canonical: `https://neredeizlenir.com${routes.liveTv.index}/${channelSlugUrl}`,
    keywords: `${channelName} izle, ${channelName} Canlı Yayın, ${channelName} Yayın Akışı`,
  }
}
